<template>
  <div class="login-wrap">
    <el-link
      type="primary"
      @click="switchLanguage"
      class="language"
      tabindex="null"
      >{{ $t("system.changeLocale") }}</el-link
    >
    <div class="ms-login">
      <div class="ms-title">ToLead-Admin</div>
      <el-form
        :model="param"
        :rules="rules"
        ref="login"
        label-width="0px"
        class="ms-content"
      >
        <el-form-item prop="username">
          <el-input
            v-model="param.username"
            :placeholder="$t('user.account')"
            tabindex="1"
          >
            <template #prepend>
              <el-button icon="el-icon-user"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            :placeholder="$t('user.password')"
            v-model="param.password"
            @keyup.enter="submitForm()"
            tabindex="2"
          >
            <template #prepend>
              <el-button icon="el-icon-lock"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button
            type="primary"
            @click="submitForm()"
            tabindex="3"
            :loading="isLoading"
            >{{ $t("system.login") }}</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import mainServer from "@/api/index";
export default {
  data() {
    return {
      isLoading: false,
      param: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("user.account"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("user.password"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.$store.commit("clearTags");
    localStorage.removeItem("ms_username");
    localStorage.removeItem("access_token");
  },
  methods: {
    switchLanguage(e) {
      e.preventDefault();
      this.$i18n.locale == "zh-cn"
        ? (this.$i18n.locale = "en")
        : (this.$i18n.locale = "zh-cn");
      localStorage.setItem("locale", this.$i18n.locale);
    },
    submitForm() {
      this.$refs.login.validate((valid) => {
        if (valid) {
          this.isLoading = true;
          mainServer
            .login(this.param)
            .then((res) => {
              this.isLoading = false;

             
              let permissions = res.data.permissions
                ? res.data.permissions
                : [];
              if (!permissions) {
                permissions = [];
              }

              if (permissions.length == 0) {
                this.$message.error(this.$t("system.loginFail"));
              } else {
                this.$message.success(this.$t("system.loginSuccess"));
                localStorage.setItem("ms_username", res.data.name);
                localStorage.setItem("access_token", res.data.access_token);
                localStorage.setItem("user", JSON.stringify(res.data));
                localStorage.setItem("permissions",JSON.stringify(permissions))
                this.$router.push("/");
              }
            })
            .catch(() => {
              this.isLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/login-bg.jpg);
  background-size: 100%;
}
.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: rgb(7, 93, 173);
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}

.language {
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 16px;
  color: black !important;
}
</style>